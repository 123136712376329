import { useEffect, useState } from "react";
import { useAppSelector } from "../../store";
import { GetReviewHistory } from "../../store/services/DishService";
import { Box } from "@mui/material";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { toast } from "react-toastify";

interface ReviewData {
  _id: string;
  createdAt: number;
  status: string;
  // Add other properties based on your actual data structure
}

export const ReviewHistory = () => {
  const userData = useAppSelector((state) => state.userReducer);
  const reviewHistoryData = useAppSelector(
    (state) => state.masterStore.reviewHistoryData
  );
  const [rows, setRows] = useState<ReviewData[]>([]);
  const [loading, setLoading] = useState(true);

  const formatDate = (timestamp: number): string => {
    return new Date(timestamp).toDateString();
  };

  const fetchData = async () => {
    try {
      let tempReviews: ReviewData[] = [];

      if (!reviewHistoryData) {
        const res = await GetReviewHistory(userData.restaurantId);

        if (res.status === 200) {
          const { data } = res.data;

          if (!data) return;
          const { allReviews } = res.data?.data?.reviewManger;

          tempReviews = Object.values(allReviews);
        }
      } else {
        const { allReviews } = reviewHistoryData;
        tempReviews = Object.values(allReviews);
      }

      setRows(tempReviews);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columns: GridColDef[] = [
    { field: "_id", headerName: "ID", width: 130 },
    {
      field: "createdAt",
      headerName: "Review Date",
      width: 150,
      valueGetter: (params: GridValueGetterParams) =>
        formatDate(Number(params.row.createdAt)),
    },
    { field: "status", headerName: "Status", width: 130 },
  ];

  useEffect(() => {
    if (userData) {
      fetchData();
    }
  }, [userData]);
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        // pageSizeOptions={[5]}
        // checkboxSelection
        // disableRowSelectionOnClick
      />
    </Box>
  );
};
