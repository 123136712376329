import { getAnalytics } from 'firebase/analytics';
import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Auth, getAuth, GoogleAuthProvider } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';


// const firebaseConfig: FirebaseOptions = {
//   apiKey: 'AIzaSyAbk_qfC9C03peN8XXLoC85-FNi9ARY2vo',
//   authDomain: 'lightworks-tech.firebaseapp.com',
//   projectId: 'lightworks-tech',
//   storageBucket: 'lightworks-tech.appspot.com',
//   messagingSenderId: '647452594556',
//   appId: '1:647452594556:web:2c7ac7eab6801a630494f9',
//   measurementId: 'G-KV7470C82F',
// };

const firebaseConfig = {
  apiKey: "AIzaSyDF5KXui_XUOa269_2anJ0po6rb2e5Y6fQ",
  authDomain: "tharasafoodapp.firebaseapp.com",
  projectId: "tharasafoodapp",
  storageBucket: "tharasafoodapp.appspot.com",
  messagingSenderId: "297060360409",
  appId: "1:297060360409:web:a3d208f37dccd7d6961ec8",
  measurementId: "G-8B9QTB26LM"
};

// Initialize Firebase
export const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export const analytics = getAnalytics(app);
export const googleProvider = new GoogleAuthProvider();
export const storage: FirebaseStorage = getStorage(app);
