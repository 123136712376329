import axios from "axios";
import { AxiosApi, PublicUrl } from "../../config";
import { DishSchemaType } from "../../types";
import { DishData } from "../../pages/store/dish/dish-card";

export interface DishInterface extends DishSchemaType {
  dishStatus: "AVAILABLE" | "UNAVAILABLE";
  dishName: string;
  description: string;
  cuisine?: string;
  price: number;
  currency?: string;
  restaurantId: string;
  images: string[];
  isVeg: boolean;
  other?: { [key: string]: string };
  extras?: { [key: string]: any };
  dishId: string;
}

export interface DishStatus {
  status: "AVAILABLE" | "UNAVAILABLE";
  dishId: string;
}

const PostAddDish = async (data: DishInterface, resId: string) => {
  return AxiosApi.post(`addDish/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const GetMenu = async (resId: string) => {
  return AxiosApi.get(`getMenu/${resId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const ChangeDishStatus = async (data: DishStatus, resId: string) => {
  return AxiosApi.put(`setDishStatus/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const changeRestaurantCover = async (img: string, resId: string) => {
  return AxiosApi.post(`update-cover-photo/${resId}`, {image: img}, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const PublicDishes = async (resId: string) => {
  return AxiosApi.get(PublicUrl + `public-view/${resId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const GetReviewHistory = async (resId: string) => {
  return AxiosApi.get(`get-review-history/${resId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const UpdateDishByDishId = async (resId: string, data: DishInterface) => {
  return AxiosApi.post(`/update-dish/${resId}/${data.dishId}`, { dishDetails: data }, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const DeleteDishByDishId = async (resId: string, data: DishData) => {
  return AxiosApi.post(`/remove-dish/${resId}/${data.dishId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const RaiseReview = async (resId: string) => {
  return AxiosApi.post(`/raise-review-request/${resId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

export {
  PostAddDish,
  GetMenu,
  ChangeDishStatus,
  PublicDishes,
  GetReviewHistory,
  DeleteDishByDishId,
  UpdateDishByDishId,
  RaiseReview,
  changeRestaurantCover
};
