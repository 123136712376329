import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Paper,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import FileUpload from "../../restaurant/components/fileUpload";
import { DishSchemaType } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  DishInterface,
  UpdateDishByDishId,
} from "../../../store/services/DishService";
import { toast } from "react-toastify";
import { DishData } from "./editable-dish-card";
import { UpdateAdminStoreByDishId } from "../../../store/reducers/store-reducer";

const EditDish: React.FC<{
  onDishPreview?: (data: any) => void;
  dishData?: DishData;
}> = ({ onDishPreview, dishData }) => {
  // State to manage form data
  const [formData, setFormData] = useState<Partial<DishSchemaType>>({
    dishStatus: "AVAILABLE",
    dishName: "",
    description: "",
    cuisine: "",
    price: 0,
    currency: "INR",
    restaurantId: "",
    images: [],
    isVeg: false,
    other: {
      dishImages: "",
    },
    variants: {},
    selected: {},
    dishId: "AUTO",
  });
  const [dishImage, setDishImage] = useState<File[]>([]);
  const [isVeg, setIsVeg] = useState("");
  const userData = useAppSelector((state) => state.userReducer);
  const dispatch = useAppDispatch();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const res = await UpdateDishByDishId(userData.restaurantId, setDishData());
    if (res.status === 200) {
      dispatch(UpdateAdminStoreByDishId(setDishData()));
      toast.success("Dish Updated successfully", {
        toastId: "success",
      });
      document.getElementById("closebtn2")?.click();
    }
  };

  const resetForm = () => {
    setFormData({
      dishStatus: "AVAILABLE",
      dishName: "",
      description: "",
      cuisine: "",
      price: 0,
      currency: "INR",
      restaurantId: "",
      images: [],
      isVeg: false,
      other: {
        dishImages: "",
      },
      variants: {},
      selected: {},
      dishId: "AUTO",
    });
    if (onDishPreview) {
      onDishPreview({});
      setDishImage([]);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const getDishImage = (documents: File[]) => {
    setDishImage(documents);
  };

  const setDishData = (): DishInterface => {
    const payload = {
      ...formData,
      restaurantId: userData.restaurantId,
      isVeg: isVeg === "VEG" ? true : false,
      images: dishImage.map((v: any) => v.url),
      extras: {
        ...formData.extras,
        isApproved: false,
        dishImages: dishImage,
      },
    };
    return payload as DishInterface;
  };

  const handlePreview = () => {
    if (onDishPreview) {
      onDishPreview(setDishData());
    }
  };

  useEffect(() => {
    if (userData) {
      setFormData({
        ...formData,
        restaurantId: userData.restaurantId,
      });
    }
  }, [userData]);

  useEffect(() => {
    if (!!dishData) {
      setFormData(dishData);
      setIsVeg(dishData.isVeg ? "VEG" : "NON-VEG");
      setDishImage(dishData?.extras?.dishImages);
    }
  }, [dishData]);

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        width: "100%",
        margin: "auto",
        gap: "5px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <form onSubmit={handleSubmit}>
        <label>Upload Dish Images</label>
        <FileUpload
          isMultiple={true}
          name="dishForm"
          onFileUpload={getDishImage}
          onRemoveFile={getDishImage}
          size="20"
          uploadedfiles={dishImage ? dishImage : []}
          accept="image"
        ></FileUpload>
        <TextField
          style={{ marginTop: "20px" }}
          fullWidth
          label="Dish Name"
          name="dishName"
          value={formData.dishName}
          onChange={handleChange}
          required
        />
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid item xs={6}>
            <InputLabel style={{ fontSize: "12px" }}>Dish Status *</InputLabel>
            <Select
              required
              defaultValue="AVAILABLE"
              value={formData.dishStatus}
              label="Dish Status"
              name="dishStatus"
              fullWidth
              placeholder="Dish Status"
              onChange={(event) => handleChange(event)}
            >
              <MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
              <MenuItem value="UNAVAILABLE">UNAVAILABLE</MenuItem>
            </Select>
          </Grid>

          <Grid xs={6} item>
            <InputLabel style={{ fontSize: "12px" }}>Food Type *</InputLabel>
            <Select
              required
              defaultValue="VEG"
              value={isVeg}
              label=""
              name="foodType"
              fullWidth
              placeholder="Food Type"
              onChange={(event) => setIsVeg(event.target.value)}
            >
              <MenuItem value="VEG">VEG</MenuItem>
              <MenuItem value="NON-VEG">NON-VEG</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid xs={6} item>
            <TextField
              fullWidth
              label="Cuisine"
              name="cuisine"
              value={formData.cuisine}
              onChange={handleChange}
              margin="normal"
            />
          </Grid>
          <Grid xs={6} item>
            <TextField
              fullWidth
              label="Price"
              name="price"
              type="number"
              value={formData.price}
              onChange={handleChange}
              margin="normal"
              required
            />
          </Grid>
        </Grid>
        <InputLabel style={{ fontSize: "12px" }}>Currency *</InputLabel>
        <Select
          required
          defaultValue=""
          value={formData.currency}
          name="currency"
          fullWidth
          placeholder="currency"
          onChange={handleChange}
        >
          <MenuItem value="INR">INR</MenuItem>
          <MenuItem value="RIYAL">RIYAL</MenuItem>
        </Select>
        <TextField
          fullWidth
          label="Description"
          name="description"
          multiline
          rows={4}
          value={formData.description}
          onChange={handleChange}
          margin="normal"
          required
        />
        <Grid container spacing={2} style={{ marginTop: "5px" }}>
          <Grid xs={6} item>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handlePreview}
            >
              View Preview
            </Button>
          </Grid>
          <Grid xs={6} item>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default EditDish;
