import React, { useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, Grid, LinearProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../store";
import { GetAllDetailsByResId } from "../store/services/FormService";
import { MasterDetails } from "../store/reducers/master-reducer";
import { useNavigate } from "react-router-dom";
const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface HeaderProps {
  open: boolean;
  handleDrawerOpen: () => void;
  isLoaderActive: boolean;
}

const Header: React.FC<HeaderProps> = ({
  open,
  handleDrawerOpen,
  isLoaderActive,
}) => {
  const userData = useAppSelector((state) => state.userReducer);
  const fullData = useAppSelector((state) => state.masterReducer);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const dispatch = useAppDispatch();
  const fetchData = async () => {
    try {
      const resId = userData.restaurantId;
      const details = await GetAllDetailsByResId(resId);

      if (details?.status === 200) {
        const { data } = details;
        dispatch(MasterDetails(data.data));
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchData();
  }, [userData]);

  const handlePopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : " ";

  const RestaurantName =
    fullData && fullData.data && fullData.data?.storeView
      ? fullData.data?.storeView?.displayName
      : "Tharasa Foods Partner";

  return (
    <AppBar position="fixed" open={open}>
      {isLoaderActive && (
        <div className="wrapper">
          <div style={{ position: "absolute", top: "0px", width: "100%" }}>
            <LinearProgress color="warning" />
          </div>
        </div>
      )}
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => handleDrawerOpen()}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Grid container spacing={2}>
          <Grid item xs={11} alignSelf={"center"}>
            <Typography
              id="exit"
              variant="h6"
              noWrap
              component="div"
              justifyContent={"center"}
            >
              Welcome, {RestaurantName} !
            </Typography>
          </Grid>
          <Grid xs={1} item>
            <div
              id={id}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: "4px",
              }}
              onClick={handlePopover}
            >
              <AccountCircleIcon></AccountCircleIcon>
              <Button color="warning" variant="text" onClick={logOut}>
                {" "}
                Logout
              </Button>
              <div>{userData.email}</div>
            </div>
          </Grid>
        </Grid>
        {/* <Popover
          anchorEl={anchorEl}
          open={openPopover}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              padding: "10px",
              width: "100px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{ color: "red", fontSize: "14px", cursor: "pointer" }}
              onClick={logOut}
            >
              Logout
            </div>
          </div>
        </Popover> */}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
