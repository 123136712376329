import { Box, Typography } from "@mui/material";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "50vh",
  marginTop: "16px",
};

interface Location {
  lat: number;
  lng: number;
}

const GMaps = ({ lat, lng }: Location) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBLTnYx7iaZVKIwZ9isXpp6ycu8LLB4XAo", //TODO
    libraries: ["places"],
  });

  const mapOptions = {
    disableDefaultUI: true, // Disable default UI interactions
    clickableIcons: false,
    draggable: false,
    fullscreenControl: false,
    keyboardShortcuts: false
  };

  useEffect(() => {
    setUserLocation({ lat, lng });
  }, [lat, lng]);

  const [userLocation, setUserLocation] = useState<Location>(
    { lat, lng } || {
      lat: 7.2905715,
      lng: 80.6337262,
    }
  );

  if (loadError) {
    return <Typography>Maps Loading Error...</Typography>;
  }

  if (!isLoaded) {
    return <Typography>Maps Loading...</Typography>;
  }

  return (
    <Box>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={userLocation || { lat: 7.2905715, lng: 80.6337262 }}
        options={mapOptions}
      >
        {userLocation && <Marker position={userLocation} draggable />}
      </GoogleMap>
    </Box>
  );
};

export default GMaps;
