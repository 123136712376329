import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "firebase/auth";

interface InitialStateProp {
  firebaseUser: User | null;
  token: string | null;
  email: string;
  restaurantId: string;
}

const initialState: InitialStateProp = {
  firebaseUser: null,
  email: "",
  token: null,
  restaurantId: "",
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: initialState,
  reducers: {
    logIntoApp: (
      state,
      action: PayloadAction<{ token: string; restaurantId: string }>
    ) => {
      localStorage.setItem("auth-token", action.payload.token);
      localStorage.setItem("restaurant-id", action.payload.restaurantId);
      state.token = action.payload.token;
      state.restaurantId = action.payload.restaurantId;
    },
    logoutApp: (state) => {
      localStorage.clear();
      state.token = null;
    },
  },
});

export const { logIntoApp, logoutApp } = authSlice.actions;

export default authSlice.reducer;
