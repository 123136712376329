import { AxiosApi } from "../../config";

interface TimePeriod {
  year: string;
  monthName: string;
}

const GetOrders = async (resId: string, data: TimePeriod) => {
  return AxiosApi.post(`getOrders/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

export { GetOrders };
