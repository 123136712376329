import { Button, CardMedia, Chip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
export const OrderCard: React.FC<{
  orderData: any;
}> = ({ orderData }) => {
  const [copyOrderData, setCopyOrderData] = useState({
    ...orderData,
    modifiedOrderDetails: [],
  });

  const getLabel = (status: string) => {
    switch (status) {
      case "ACTIVE":
        return "warning";
      case "ACCEPT":
        return "success";
      case "REJECTED":
        return "error";
    }
    return "success";
  };

  useEffect(() => {
    const keys = Object.keys(copyOrderData.orderItems || {});
    const orderDetails = keys.map((dishId) => {
      return copyOrderData.orderItems[dishId];
    });

    setCopyOrderData((prevData: any) => ({
      ...prevData,
      modifiedOrderDetails: orderDetails,
    }));
  }, [orderData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6" fontSize={14} gap={2}>
            Order ID: {copyOrderData?.orderId}
          </Typography>
          <Chip
            label={copyOrderData.orderStatus}
            color={getLabel(copyOrderData.orderStatus)}
          >
            {copyOrderData.orderStatus}
          </Chip>
        </Grid>
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <div style={{ fontSize: 16, fontWeight: "bold" }}>Order Items:</div>
        </Grid>
        {copyOrderData?.modifiedOrderDetails.length > 0 &&
          copyOrderData?.modifiedOrderDetails.map(
            (item: any, index: number) => (
              <Grid
                key={index}
                item
                xs={12}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    gap: "15px",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="dish"
                    width={"70px"}
                    height={"70px"}
                    style={{ borderRadius: "12px" }}
                    src={
                      item.dishDetails?.images[0]
                        ? item.dishDetails?.images[0]
                        : ""
                    }
                  />
                  <div
                    style={{ fontSize: 16 }}
                  >{`${item.quantity} x ${item.dishDetails.dishName}`}</div>
                </div>
                <div style={{ fontSize: 16 }}>{`${getSymbolFromCurrency(
                  item.dishDetails.currency
                )}${item.dishDetails.price}`}</div>
              </Grid>
            )
          )}
        <Grid
          item
          xs={12}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <div style={{ fontSize: 16, fontWeight: "bold" }}>Total</div>
          <div style={{ fontSize: 16 }}>${copyOrderData.orderAmount}</div>
        </Grid>
      </Grid>
    </>
  );
};
