import { createSlice } from "@reduxjs/toolkit";

const initialState: { data: any } = {
  data: null,
}

export const MasterDetailsSlice = createSlice({
  name: "MasterDetails",
  initialState,
  reducers: {
    MasterDetails: (state: any, action) => {
      if (state.data) {
        state.data = { ...state.data, ...action.payload };
      } else {
        state.data = action.payload;
      }
    },
    MasterProfileDetails: (state: any, action) => {
      if (state.data) {
        state.data.profile = { ...state.data.profile, ...action.payload };
      } else {
        state.data.profile = action.payload;
      }
    },
    MasterOwnerDetails: (state: any, action) => {
      if (state.data) {
        state.data.ownerProfile = {
          ...state.data.ownerProfile,
          ...action.payload.ownerProfile,
        };
      } else {
        state.data.ownerProfile = action.payload;
      }
    },
    MasterDocumentsDetails: (state: any, action) => {
      if (state.data) {
        state.data.documents = { ...state.data.documents, ...action.payload };
      } else {
        state.data.documents = action.payload;
      }
    },
  },
});

export const {
  MasterDetails,
  MasterProfileDetails,
  MasterOwnerDetails,
  MasterDocumentsDetails,
} = MasterDetailsSlice.actions;

export default MasterDetailsSlice.reducer;
