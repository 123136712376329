import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import AddDish from "./dish/add-dish";
import FileUpload from "react-material-file-upload";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import DishCard, { DishData } from "./dish/dish-card";
import { useState } from "react";

// const sampleDishData: DishData = {
//   dishName: "Sample Dish",
//   cuisine: "Sample Cuisine",
//   description: "A tasty sample dish for testing",
//   price: 15.99,
//   currency: "USD",
//   dishStatus: "AVAILABLE",
// };

const AddDishView = () => {
  const [previewDishData, setPreviewDishData] = useState<DishData>({
    cuisine: "",
    currency: "",
    description: "",
    dishName: "",
    dishStatus: "AVAILABLE",
    price: 0,
    images: [],
    isVeg: false,
    extras: {
      isApproval: false,
    },
    other: {},
    dishId: "",
    restaurantId: "",
  });
  const onDishPreview = (data: any) => {
    console.log("asdad",data);

    setPreviewDishData(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4" color="initial">
          Add New Dish
        </Typography>
      </Grid>

      <Grid item xs={12} md={8}>
        <AddDish onDishPreview={onDishPreview} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" align="center" gutterBottom>
          Preview
        </Typography>
        <DishCard dishData={previewDishData} isPreview={true} />
      </Grid>
    </Grid>
  );
};

export { AddDishView };
