import { AxiosApi } from "../../config";
import {
  DocumentsType,
  OwnerProfileSchemaType,
  ProfileSchemaType,
} from "../../types";

export interface OwnerInfo {
  ownerProfile: Partial<OwnerProfileSchemaType>;
}

export interface ProfileInfo {
  profile: Partial<ProfileSchemaType>;
}

export interface DocumentsInfo {
  documents: Partial<DocumentsType>;
}

export interface Timings {
  openingTime: string;
  closingTime: string;
}

const OwnerInfoUpdate = async (data: OwnerInfo, resId: string) => {
  return AxiosApi.put(`updateOwnerProfile/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};
const ProfileInfoUpdate = async (data: ProfileInfo, resId: string) => {
  return AxiosApi.put(`updateProfile/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const UpdateDocuments = async (data: DocumentsInfo, resId: string) => {
  return AxiosApi.post(`updateDocuments/${resId}`, data, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const GetAllDetailsByResId = async (resId: string) => {
  return AxiosApi.get(`${resId}`, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};
const PostFinalSubmit = async (resId: string) => {
  const payload = {
    status: "SUBMITTED",
  };
  return AxiosApi.put(`application-status/${resId}`, payload, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

const UpdateOpeningTimeClosingTime = async (
  resId: string,
  payload: Timings
) => {
  return AxiosApi.put(`setTimings/${resId}`, payload, {
    headers: {
      "Content-type": "application/json",
      accept: "application/json",
    },
  });
};

export {
  OwnerInfoUpdate,
  ProfileInfoUpdate,
  UpdateDocuments,
  GetAllDetailsByResId,
  PostFinalSubmit,
  UpdateOpeningTimeClosingTime,
};
