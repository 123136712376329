import { Box } from "@mui/system";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { GetOrders } from "../../store/services/OrderService";
import { OrderDetails } from "../../store/reducers/order-reducer";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import { OrderCard } from "./components";

export const Orders = () => {
  const userData = useAppSelector((state) => state.userReducer);
  const [rows, setRows] = useState([]);
  const dispatch = useAppDispatch();
  const [selectedOrderData, setSelectedOrderData] = useState<any>();
  const [openOrderData, setOpenOrderData] = useState(false);
  const fetchData = async () => {
    try {
      const res = await GetOrders(userData.restaurantId, {
        year: "2023",
        monthName: "december",
      });

      if (res.status === 200) {
        setRows(res?.data?.data);
        dispatch(OrderDetails(res?.data?.data));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchData();
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      { field: "_id", headerName: "Order Id", width: 130 },
      { field: "orderAmount", headerName: "Amount", width: 150 },
      { field: "orderStatus", headerName: "Order status", width: 130 },
    ],
    []
  );
  const handleRowSelection = (event: any) => {
    console.log(event.row);
    setSelectedOrderData(event.row);
    setOpenOrderData(true);
  };

  const calculateHeight = (height: number) => height - 150;

  return (
    <Box sx={{ height: calculateHeight(window.innerHeight), width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[5]}
        onRowClick={handleRowSelection}
        {...rows}
      />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: "500px",
            },
          },
        }}
        open={openOrderData}
        onClose={() => setOpenOrderData(false)}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #0000001A",
          }}
        >
          <DialogTitle>Order Details</DialogTitle>
          <div style={{ padding: "0px 12px", cursor: "pointer" }}>
            <CloseIcon color="error" onClick={() => setOpenOrderData(false)} />
          </div>
        </div>

        <DialogContent>
          <OrderCard orderData={selectedOrderData} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};
