import { Checkbox, FormControlLabel, useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WALLPAPER from "../../assests/bg-wall.png";
import { logIntoApp } from "../../store/reducers/user-reducer";
import { sendOtp, verifytOtp } from "../../store/services/auth-service";
import React, { useState } from "react";
import { toast } from "react-toastify";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignInSide = () => {

  const [otp, setOtp] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState("");
  const [ptpSent, setOtpSent] = React.useState(false);
  const [resendOtp, setResenOtp] = React.useState(true);
  const [isTimerRunning, setIsTimerRunning] = React.useState(false);
  const [seconds, setSeconds] = React.useState(60);
  const [isloading, setLoading] = React.useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const [checkBoxValue, setCheckBoxValue] = useState(false);

  React.useEffect(() => {
    let timer: any = null;

    if (isTimerRunning) {
      timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds((prevSeconds) => prevSeconds - 1);
        } else {
          clearInterval(timer);
          setIsTimerRunning(false);
          setResenOtp(false);
        }
      }, 1000);
    }

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [isTimerRunning, seconds]);

  const startTimer = () => {
    setResenOtp(true);
    if (!isTimerRunning) {
      setIsTimerRunning(true);
    }
    if (seconds < 5) {
      setSeconds(60);
    }
  };

  const triggerOtp = async () => {
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response = await sendOtp(mobileNumber);
      if (response && response.data && response.data?.isSuccess) {
        setOtpSent(true);
        startTimer();
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const verifyMyOtp = async () => {
    try {
      setLoading(true);
      const response = await verifytOtp(mobileNumber, otp);
      if (response && response.data && response.data.isSuccess) {
        dispatcher(logIntoApp(response.data.data));
        navigate("/dashboard/form");
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const validateForm = () => {
    let isValid = true;
    let msg = "";

    if (!mobileNumber) {
      msg = msg + "Please enter valid mobile number";
      isValid = false;
    }

    if (!checkBoxValue) {
      msg = msg + "Please accept terms and conditions";
      isValid = false;
    }

    if (!isValid) {
      toast.warning(msg.trim(), {
        toastId: "validateform",
      });
    }

    return isValid;
  };

  return (
    <>
      {isloading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="warning" />
        </Box>
      )}
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
        }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${WALLPAPER})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: theme.palette.background.paper,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            backgroundColor: theme.palette.primary.main,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          {/* <Box pl={5} pr={5}>
            <Typography component="h1" variant="h2" color={"white"}>
              Partner with us
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "white" }}>
              Join Tharasafoodadda and become a valued partner. Collaborate with
              us to enhance your restaurant's reach and delight customers with
              your delicious offerings.
            </Typography>
          </Box> */}
          <Box
            p={{ md: 10, xs: 2, sm: 2 }}
            mx={{ xs: 0, md: 0, sm: 2, lg: 8 }}
            sx={{
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",

              borderRadius: 10,
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              color={"black"}
              style={{
                textDecoration: "underline",
                color: theme.palette.primary.main,
              }}
            >
              Partner with us
            </Typography>
            <Avatar
              sx={{
                m: 1,
                bgcolor: "secondary.main",
                width: "200px",
                height: "200px",
              }}
              src="https://firebasestorage.googleapis.com/v0/b/hungrypanda-restaurant.appspot.com/o/application%2Flogo.png?alt=media&token=60c20f19-82dd-4b04-a45a-c94cee1d7eb2"
            />

            <Typography component="h1" variant="h5" fontWeight={"bold"}>
              Register / Login Here
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "black" }}>
              Join Tharasafoodadda and become a valued partner. Collaborate with
              us to enhance your restaurant's reach and delight customers with
              your delicious offerings.
            </Typography>
            <Box>
              {ptpSent ? (
                <>
                  <Typography variant="caption">
                    Please enter the verification code:
                  </Typography>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    inputStyle={{
                      width: "80px",
                      height: "80px",
                      fontSize: "24px",
                    }}
                    renderSeparator={
                      <span style={{ fontSize: "24px" }}>-</span>
                    }
                    renderInput={(props) => <input {...props} />}
                  />
                  <Box style={{flexDirection:"row"}} mt={2}>
                    <Typography variant="caption" style={{color: theme.palette.error.dark}}>
                      {" "}
                      You can resend otp in {seconds} seconds
                    </Typography>
                    <Button
                      variant="text"
                      onClick={triggerOtp}
                      disabled={resendOtp}
                    >
                      Resend
                    </Button>
                  </Box>
                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={verifyMyOtp}
                  >
                    Verify OTP
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mobile"
                    label="Mobile number"
                    name="mobileNumber"
                    autoFocus
                    type="text"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 10,
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checkBoxValue}
                        onChange={() => setCheckBoxValue((prev) => !prev)}
                      />
                    }
                    label={
                      <Typography variant="body2" mt={1}>
                        I have read and agree to the{" "}
                        <a
                          href="https://tharasafoodadda-partnerwithus.web.app/terms"
                          style={{ textDecoration: "underline" }}
                        >
                          Terms & Conditions of Tharasafoodadda
                        </a>
                      </Typography>
                    }
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  />

                  <Button
                    fullWidth
                    variant="outlined"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={triggerOtp}
                  >
                    Proceed
                  </Button>
                </>
              )}

              <Grid container></Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export { SignInSide };
