import * as React from "react";
import Grid from "@mui/material/Grid";
import AddDish from "./edit-dish";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { DishData } from "./editable-dish-card";
import DishCard from "../../store/dish/dish-card";
import EditDish from "./edit-dish";

// const sampleDishData: DishData = {
//   dishName: "Sample Dish",
//   cuisine: "Sample Cuisine",
//   description: "A tasty sample dish for testing",
//   price: 15.99,
//   currency: "USD",
//   dishStatus: "AVAILABLE",
// };

const EditDishView: React.FC<{
  dishData?: DishData;
}> = ({ dishData }) => {
  const [previewDishData, setPreviewDishData] = useState<DishData>({
    cuisine: "",
    currency: "",
    description: "",
    dishName: "",
    dishStatus: "AVAILABLE",
    price: 0,
    images: [],
    isVeg: false,
    extras: {
      isApproval: false,
    },
    other: {},
    dishId: "",
    restaurantId: "",
  });
  const onDishPreview = (data: any) => {
    setPreviewDishData(data);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <EditDish onDishPreview={onDishPreview} dishData={dishData} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" align="center" gutterBottom>
          Preview
        </Typography>
        <DishCard dishData={previewDishData} isPreview={true} />
      </Grid>
    </Grid>
  );
};

export { EditDishView };
