import { Button, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  GoogleMap,
  Marker,
  useLoadScript,
} from "@react-google-maps/api";
import React, { useEffect, useRef, useState } from "react";
import "./auto-complete.css";

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "50vh",
  marginTop: "16px",
};

interface Location {
  lat: number;
  lng: number;
}

interface Props {
  latLong?(Location: Location): () => void;
  sendLocation?: (Location: { latitude: number; longitude: number }) => void;
}

const AddressMap: React.FC<Props> = ({ latLong, sendLocation }) => {
  const [userLocation, setUserLocation] = useState<Location | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const autocomplete = useRef<any>(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBLTnYx7iaZVKIwZ9isXpp6ycu8LLB4XAo", // Replace with your API key
    libraries: ["places"],
  });

  const updateCords = async () => {
    if (sendLocation && userLocation) {
      sendLocation({latitude:userLocation.lat, longitude: userLocation.lng});
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
        if (latLong) {
          latLong({ lat: latitude, lng: longitude });
        }
      },
      (error) => {
        console.error("Error getting user location:", error);
      }
    );
  }, [latLong]);

  const handleMarkerDragEnd = (event: any) => {
    // Access the new marker position from the event
    const newLat = event.latLng.lat();
    const newLng = event.latLng.lng();

    // Update the user location state
    setUserLocation({ lat: newLat, lng: newLng });

    // Handle any other logic you need on marker drag end
    console.log("Marker dragged to:", newLat, newLng);
  };

  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (place.geometry && place.geometry.location && place.formatted_address) {
      setSearchValue(place.formatted_address);
      const { lat, lng } = place.geometry.location.toJSON();
      setUserLocation({ lat, lng });
    }
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div style={{ position: "relative", padding: "16px" }}>
      <Typography variant="h5">Add your location</Typography>
      <Typography variant="caption" mb={16}>
        Search for your location here and select it to view the corresponding
        pointer on the maps
      </Typography>
      <Autocomplete
        className="lw-auto"
        onLoad={(autocompleteInstance) => {
          autocomplete.current = autocompleteInstance;
        }}
        onPlaceChanged={() => {
          const place = autocomplete.current.getPlace();
          handlePlaceSelect(place);
        }}
      >
        <TextField
          type="text"
          placeholder="Search for a place"
          variant="outlined"
          value={searchValue}
          label="Enter your Location"
          onChange={(e) => setSearchValue(e.target.value)}
          style={{ marginTop: 10 }}
          fullWidth
        />
      </Autocomplete>
      <Typography variant="h5" mt={4}>
        Point your location
      </Typography>
      <Typography variant="caption" mb={16}>
        For the delivery boy to efficiently pick up and promptly deliver the
        order to the customer, please update the location with greater precision
        on Google Maps.
      </Typography>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={15}
        center={userLocation || { lat: 7.2905715, lng: 80.6337262 }}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            draggable
            onDragEnd={handleMarkerDragEnd}
          />
        )}
      </GoogleMap>

      <Button
        variant="contained"
        style={{ marginTop: 10 }}
        onClick={updateCords}
      >
        Submit Location
      </Button>
    </div>
  );
};

export default AddressMap;
