import React, { useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./App.css";
import { theme } from "./theme";
import AppRoutes from "./routes/app-routes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { AxiosApi } from "./config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  const [isLoaderActive, setIsLoaderActive] = useState(false);

  const showLoader = () => {
    setIsLoaderActive(true);
  };

  const hideLoader = () => {
    setIsLoaderActive(false);
  };

  AxiosApi.interceptors.request.use(
    (config) => {
      showLoader();

      const authToken = localStorage.getItem("auth-token");
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (error) => {
      hideLoader();

      return Promise.reject(error);
    }
  );

  AxiosApi.interceptors.response.use(
    (response) => {
      hideLoader();
      return response;
    },
    (error) => {
      hideLoader();
      toast.error(error?.response?.data?.message || "something went Wrong", {
        toastId: "error",
      });
      return Promise.reject(error);
    }
  );

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Provider store={store}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              theme="light"
            />
            <AppRoutes isLoaderActive={isLoaderActive} />
          </Provider>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
