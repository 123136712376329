import React from "react";
import { Container, Typography, Button, Stack } from "@mui/material";
import { PHONE_IMG } from "../../constants/assets";

const AppDownloadPage: React.FC = () => {
  return (
    <Container
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Dummy Image for Mobile App */}
      <img
        src={PHONE_IMG} // Replace with your mobile app image URL
        alt="Mobile App"
        style={{
          maxWidth: "100%",
          height: "auto",
        }}
      />

      <Stack padding={10}>
        <Typography
          variant="h4"
          style={{
            marginTop: "16px",
            marginBottom: "8px",
          }}
          align="center"
        >
          Download Our Mobile App
        </Typography>

        <Typography
          variant="body1"
          style={{
            marginBottom: "24px",
          }}
          align="center"
        >
          Explore the features of our amazing mobile app. Download now!
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            marginTop: "16px",
          }}
        >
          Download Now
        </Button>
      </Stack>
    </Container>
  );
};

export default AppDownloadPage;
