import { createSlice } from "@reduxjs/toolkit";

export const OrderSlice = createSlice({
  name: "OrderDetails",
  initialState: {
    orders: null,
  },
  reducers: {
    OrderDetails: (state: any, action) => {
      // If data already exists, merge the new data with the previous data
      if (state.orders) {
        state.orders = { ...state.orders, ...action.payload };
      } else {
        state.orders = action.payload;
      }
    },
  },
});

export const { OrderDetails } = OrderSlice.actions;

export default OrderSlice.reducer;
