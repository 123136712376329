import { useEffect, useState } from "react";
import DishCard, { DishData } from "./dish/dish-card";
import { useAppDispatch, useAppSelector } from "../../store";
import { PublicDishes } from "../../store/services/DishService";
import { Alert, Box, CardMedia, Grid, Tab, Typography } from "@mui/material";
import styled from "styled-components";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import { PublicStoreDetails } from "../../store/reducers/store-reducer";

const StoreView = () => {
  const userData = useAppSelector((state) => state.userReducer);
  const publicStoreData = useAppSelector(
    (state) => state.masterStore.publicStoreData
  );
  const storeViewData: any = useAppSelector(
    (state) => state.masterReducer.data
  );
  const dispatch = useAppDispatch();
  const fetchPublicViewData = async () => {
    try {
      if (!publicStoreData) {
        const res = await PublicDishes(userData.restaurantId);

        if (res.status === 200) {
          const menuObjectData = JSON.parse(JSON.stringify(res.data.data.menu));
          let keys = Object.keys(menuObjectData);
          let tempMenuData: any = [];

          keys.forEach((menuId) => {
            tempMenuData.push(menuObjectData[menuId]);
          });

          setPublicMenuData(tempMenuData);
          dispatch(PublicStoreDetails(menuObjectData));
        } else {
          // Handle other non-200 status codes or specific error scenarios
          console.error(
            "Error fetching public data:",
            res.status,
            res.data.message
          );
        }
      } else {
        const menuObjectData = JSON.parse(JSON.stringify(publicStoreData));
        let keys = Object.keys(menuObjectData);
        let tempMenuData: any = [];

        keys.forEach((menuId) => {
          tempMenuData.push(menuObjectData[menuId]);
        });

        setPublicMenuData(tempMenuData);
      }
    } catch (error) {
      console.error("Unexpected error during data fetching:", error);
    }
  };

  const [publicMenuData, setPublicMenuData] = useState<DishData[]>([
    {
      cuisine: "",
      currency: "",
      description: "",
      dishName: "",
      dishStatus: "AVAILABLE",
      price: 0,
      images: [],
      isVeg: false,
      extras: {},
      other: {},
      dishId: "",
      restaurantId: "",
    },
  ]);

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchPublicViewData();
  }, [userData]);

  return (
    <>
      <Typography variant="h4"> Store</Typography>
      <div style={{ padding: "5px" }}></div>
      <Alert
        icon={<OnlinePredictionIcon fontSize="inherit" />}
        sx={{
          marginBottom: "12px",
          alignItems: "center",
        }}
      >
        Customer View - This is Live!
      </Alert>
      <CardMedia
        style={{
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }}
        component="img"
        height="400px"
        image={
          "https://img.freepik.com/free-photo/top-view-circular-food-frame_23-2148723455.jpg?w=1380&t=st=1703183685~exp=1703184285~hmac=4cf9c8189e11a5ae1188db62885e81e1c5a9b71997be06ac9b04be10bafcf7dc"
        }
      ></CardMedia>
      <ContainerDiv>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                position: "absolute",
                top: "400px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "calc(100% - 150px)",
                alignItems: "center",
              }}
            >
              {`Welcome to ${storeViewData?.storeView?.displayName}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          {publicMenuData.map((data: DishData, index: number) => (
            <Grid item xs={12} sm={12} md={4} key={index}>
              <DishCard dishData={data} isPreview={false} />
            </Grid>
          ))}
        </Grid>
      </ContainerDiv>
    </>
  );
};

export { StoreView };
const ContainerDiv = styled.div`
  padding: 20px;
  border-radius: 10px;
`;
