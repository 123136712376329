import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PersonalDetailsForm } from "./forms/personal-details";
import { PaymentForm } from "./forms/payment-form";
import { Button, Grid, Typography } from "@mui/material";
import { LocalizationProvider, StaticTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useEffect, useState } from "react";
import {
  Timings,
  UpdateOpeningTimeClosingTime,
} from "../../store/services/FormService";
import { useAppSelector } from "../../store";
import { toast } from "react-toastify";
import { RestaurantDetailsForm } from "./forms/restaurant-details";
import { DcoumentsUploadForm } from "./forms/documents-upload";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import LockIcon from '@mui/icons-material/Lock';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';


const AccountView = () => {
  const [value, setValue] = useState("1");
  const [openingTime, setOpeningTime] = useState<dayjs.Dayjs | null>(null);
  const [closingTime, setClosingTime] = useState<dayjs.Dayjs | null>(null);

  const restaurantId = useAppSelector(
    (state) => state.userReducer.restaurantId
  );

  const masterReducer: any = useAppSelector(
    (state) => state.masterReducer.data
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChangeTimings = async () => {
    console.log(openingTime, closingTime);
    console.log(openingTime?.format("LT"));
    const payload: Timings = {
      closingTime: closingTime?.format("LT") as string,
      openingTime: openingTime?.format("LT") as string,
    };
    const res = await UpdateOpeningTimeClosingTime(restaurantId, payload);
    if (res.status === 200) {
      toast.success("Timings Updated Successfully", {
        toastId: "successstime",
      });
    }
  };

  useEffect(() => {
    if (!masterReducer) return;
    console.log(masterReducer);
    setOpeningTime(() =>
      dayjs(masterReducer?.openingTime ? masterReducer?.openingTime : "")
    );
    setClosingTime(() =>
      dayjs(masterReducer?.closingTime ? masterReducer?.closingTime : "")
    );
  }, [masterReducer]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" color="initial">
            Account
          </Typography>
        </Grid>
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Personal Details" value="1" />
            <Tab label="Restaurant Details" value="2" />
            <Tab label="Documents" value="3" />
            <Tab label="Billing" value="4" icon={<LockIcon/>} iconPosition="end"/>
            <Tab  label="Notifications" value="5"  icon={<NotificationsActiveIcon/>} iconPosition="end"/>
            <Tab label="Timings" value="6" icon={<AccessTimeFilledIcon/>} iconPosition="end"/>
          </TabList>
        </Box>
        <TabPanel value="1">
          <PersonalDetailsForm isAccountView={true} />
        </TabPanel>
        <TabPanel value="2">
          <RestaurantDetailsForm isAccountView={true} />
        </TabPanel>
        <TabPanel value="3">
          <DcoumentsUploadForm isAccountView={true} />
        </TabPanel>
        <TabPanel value="4">
          <PaymentForm />
        </TabPanel>
        <TabPanel value="5">No Notifications yet, Keep checking...</TabPanel>
        <TabPanel value="6">
          <Typography variant="h5">Opening Time</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticTimePicker
              value={openingTime}
              defaultValue={dayjs("2022-04-17T15:30")}
              onChange={(newValue) => setOpeningTime(newValue)}
            />
          </LocalizationProvider>
          <Typography variant="h5">Closing Time</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticTimePicker
              value={closingTime}
              defaultValue={dayjs("2022-04-17T15:30")}
              onChange={(newValue) => setClosingTime(newValue)}
            />
          </LocalizationProvider>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Button variant="contained" onClick={handleChangeTimings}>
              Update
            </Button>
          </div>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export { AccountView };
