import { AppBar, Container, Stack, Toolbar, Typography } from "@mui/material";
import React from "react";
import { DELETE_MY_ACCOUNT } from "../../constants/assets";

const UserDeleteAccount: React.FC = () => {
  return (
    <Stack>
      <AppBar>
        <Toolbar>
          <Typography variant="h6" component="div">
            THARASA FOOD ADDA - DOCS
          </Typography>
        </Toolbar>
      </AppBar>
      <Typography
        variant="h3"
        style={{
          marginBottom: "24px",
          marginTop: "80px",
        }}
        align="center"
      >
        How to permanently delete your account along with all associated data.
      </Typography>
      <Container
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* Dummy Image for Mobile App */}
        <img
          src={DELETE_MY_ACCOUNT} // Replace with your mobile app image URL
          alt="Mobile App"
          style={{
            maxWidth: "300px",
            height: "600px",
          }}
        />

        <Stack padding={10}>
          <Typography
            variant="h4"
            style={{
              marginTop: "16px",
              marginBottom: "8px",
            }}
            align="center"
          >
            Step 1
          </Typography>

          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
            }}
            align="center"
          >
            After successfully logging into the app, navigate to the profile
            section using the bottom navigation bar
          </Typography>
          <Typography
            variant="h4"
            style={{
              marginTop: "16px",
              marginBottom: "8px",
            }}
            align="center"
          >
            Step 2
          </Typography>

          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
            }}
            align="center"
          >
            In this Profile Section, you will "Delete My Account" just below
            logout button.
          </Typography>
          <Typography
            variant="h4"
            style={{
              marginTop: "16px",
              marginBottom: "8px",
            }}
            align="center"
          >
            Step 3
          </Typography>

          <Typography
            variant="body1"
            style={{
              marginBottom: "24px",
            }}
            align="center"
          >
            Click the Button "Delete My Account".
          </Typography>
        </Stack>
      </Container>
    </Stack>
  );
};

export default UserDeleteAccount;
