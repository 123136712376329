import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import SendIcon from "@mui/icons-material/Send";
import {
  GetMenu,
  GetReviewHistory,
  RaiseReview,
  changeRestaurantCover,
} from "../../store/services/DishService";
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EditableDishCard, { DishData } from "./dish/editable-dish-card";
import {
  AdminStoreDetails,
  ReviewHistoryDetails,
} from "../../store/reducers/store-reducer";
import { ReviewHistory } from "./review-history";
import { StoreUnderReview } from "./store-underreview";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import { AddDishView } from "../store/add-dish-view";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileUpload from "../restaurant/components/fileUpload";
import { DEFAULT_COVER_PHOTO } from "../../constants/assets";

interface File {
  file: string;
  url: string;
  fileType: string;
}

const StoreManagement = () => {
  const userData = useAppSelector((state) => state.userReducer);

  const adminStoreData = useAppSelector(
    (state) => state.masterStore.adminStoreData
  );

  const masterStoreData = useAppSelector(
    (state) => state.masterStore.reviewHistoryData
  );

  const storeViewData: any = useAppSelector(
    (state) => state.masterReducer.data
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDishDialog, setAddDishDialog] = useState(false);
  const [coverPicDialog, setCoverPicDialog] = useState(false);
  const [coverImage, setCoverImage] = useState<File[]>([]);

  const getCoverImage = (documents: File[]) => {
    setCoverImage(documents);
  };

  const STORE_IMAGE: string =
    storeViewData?.storeView?.image || DEFAULT_COVER_PHOTO;
  const COVER_PHOTO: string = coverImage[0] ? coverImage[0]?.url : STORE_IMAGE;

  const uploadCoverPhoto = async () => {
    if (coverImage[0] && coverImage[0]?.url) {
      const res = await changeRestaurantCover(
        coverImage[0]?.url,
        userData.restaurantId
      );
      if (res.status === 200) {
        toast.success("Cover Photo Updated successfully", {
          toastId: "success",
        });
      } else {
        toast.error("Please upoload a Photo", {
          toastId: "error",
        });
      }
    } else {
      toast.error("Please upoload a Photo", {
        toastId: "error",
      });
    }
    setCoverPicDialog(false);
  };

  const dispatch = useAppDispatch();

  const [value, setValue] = useState("1");

  const adminMenuData = useMemo(() => {
    const menuObjectData = JSON.parse(JSON.stringify(adminStoreData || {}));
    const keys = Object.keys(menuObjectData);
    return keys.map((menuId) => menuObjectData[menuId]);
  }, [adminStoreData]);

  const fetchAdminViewData = async () => {
    try {
      if (!adminStoreData) {
        const res = await GetMenu(userData.restaurantId);

        if (res.status === 200) {
          const menuObjectData = JSON.parse(JSON.stringify(res.data.data));
          dispatch(AdminStoreDetails(menuObjectData));
        } else {
          console.error(
            "Error fetching admin data:",
            res.status,
            res.data.message
          );
        }
      }
    } catch (error) {
      console.error("Unexpected error during admin data fetching:", error);
    }
  };

  const fetchData = async () => {
    try {
      if (masterStoreData) return;

      const res = await GetReviewHistory(userData.restaurantId);

      if (res.status === 200) {
        if (!res.data.data) return;

        dispatch(ReviewHistoryDetails(res?.data?.data.reviewManger));
      } else {
        // Handle other non-200 status codes or specific error scenarios
        console.error(
          "Error fetching review history data:",
          res.status,
          res.data.message
        );
      }
    } catch (error) {
      console.error("Unexpected error during data fetching:", error);
    }
  };

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleRaiseReview = async () => {
    try {
      const res = await RaiseReview(userData.restaurantId);

      if (res.status === 200) {
        toast.success("Review raised successfully", {
          toastId: "reviewSuccess",
        });
        setDialogOpen(false);
      } else {
        console.error("Error raising review:", res.status, res.data.message);
      }
    } catch (error) {
      console.error("Unexpected error during review raising:", error);
    }
  };

  useEffect(() => {
    if (!userData.restaurantId) return;
    fetchAdminViewData();
    fetchData();
  }, [userData]);

  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="lab API tabs example">
            <Tab label="Admin Store View" value="1" />
            <Tab label="Store Under Review" value="2" />
            <Tab label="Review History" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
              gap: "15px",
            }}
          >
            <Alert severity="warning">
              Please remember to submit a review that reflects the changes in
              the live store.
            </Alert>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "end",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Button
                onClick={() => setAddDishDialog(true)}
                variant="contained"
                startIcon={<AddIcon />}
                color="warning"
              >
                AddDish
              </Button>
              <Button
                onClick={() => setCoverPicDialog(true)}
                variant="contained"
                startIcon={<FileUploadIcon />}
                color="primary"
              >
                Change Restaurant Background
              </Button>

              <Button
                variant="outlined"
                endIcon={<SendIcon />}
                onClick={() => setDialogOpen(true)}
              >
                Send Review
              </Button>
            </div>
            <CardMedia
              style={{
                borderTopLeftRadius: "8px",
                borderTopRightRadius: "8px",
              }}
              component="img"
              height="400px"
              image={COVER_PHOTO}
            ></CardMedia>
            <ContainerDiv>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h5"
                    style={{
                      position: "absolute",
                      top: "430px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "calc(100% - 180px)",
                      alignItems: "center",
                    }}
                  >
                    {` Welcome to ${storeViewData?.storeView?.displayName}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {adminMenuData.map((data: DishData, index: number) => (
                  <Grid item sm={12} md={6} lg={4} xs={12} key={index}>
                    <EditableDishCard dishData={data} isPreview={false} />
                  </Grid>
                ))}
              </Grid>
            </ContainerDiv>
          </div>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure want to Raise a review ??
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
              <Button onClick={handleRaiseReview}>Raise a review</Button>
            </DialogActions>
          </Dialog>
          {/* Add Dish */}
          <Dialog
            open={addDishDialog}
            onClose={() => setAddDishDialog(false)}
            fullWidth
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "1000px", // Set your width here
                },
              },
            }}
          >
            <DialogContent>
              <AddDishView />
            </DialogContent>
            <DialogActions>
              <Button
                id="closebtn"
                variant="contained"
                onClick={() => setAddDishDialog(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Cover Photo */}
          <Dialog
            open={coverPicDialog}
            onClose={() => setCoverPicDialog(false)}
            fullWidth
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "1000px", // Set your width here
                },
              },
            }}
          >
            <DialogContent>
              <FileUpload
                isMultiple={true}
                name="dishForm"
                onFileUpload={getCoverImage}
                onRemoveFile={getCoverImage}
                size="20"
                uploadedfiles={coverImage}
                accept="image"
              ></FileUpload>
            </DialogContent>
            <DialogActions>
              <Button onClick={uploadCoverPhoto}>Submit</Button>
              <Button
                id="closebtn"
                variant="contained"
                onClick={() => setCoverPicDialog(false)}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>
        <TabPanel value="2">
          <StoreUnderReview />
        </TabPanel>
        <TabPanel value="3">
          <ReviewHistory />
        </TabPanel>
      </TabContext>
    </>
  );
};

export { StoreManagement };
const ContainerDiv = styled.div`
  padding: 20px;
  border-radius: 10px;
`;
