import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../layout/Layout";
import {
  AccountView,
  AddDishView,
  Dashboard,
  RestaurantForm,
  SignInSide,
  StoreManagement,
  StoreView,
} from "../pages";
import DashboardPrivateRoute from "./dashboard-route";
import { JoiningForm } from "../pages/restaurant/JoiningForm";
import { Orders } from "../pages/orders";
import AddressMap from "../pages/restaurant/map/address-map";
import FAQPage from "../pages/faqs/faqs";
import AppDownloadPage from "../pages/download-app/download-app";
import UserDeleteAccount from "../pages/delete-my-account/user-app-delete";

interface ComponentProps {
  isLoaderActive: boolean;
}

const AppRoutes: FC<ComponentProps> = ({ isLoaderActive }) => {
  return (
    <Routes>
      <Route path="/login" element={<SignInSide />} />
      <Route path="/delete/account/user" element={<UserDeleteAccount />} />
      <Route
        path="/dashboard"
        element={
          <DashboardPrivateRoute isLoaderActive={isLoaderActive}>
            <Layout isLoaderActive={isLoaderActive} />
          </DashboardPrivateRoute>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="form" element={<RestaurantForm />} />
        <Route path="store" element={<StoreView />} />
        <Route path="store-management" element={<StoreManagement />} />
        <Route path="orders" element={<Orders />} />
        <Route path="faqs" element={<FAQPage />} />
        <Route path="app-download" element={<AppDownloadPage />} />

        {/* <Route path="add-dish" element={<AddDishView />} /> */}
        <Route path="account-view" element={<AccountView />} />
        <Route path="store-address" element={<AddressMap />} />
        <Route path="*" element={<Navigate to="/dashboard/form" replace />} />
      </Route>
      <Route path="*" element={<Navigate to="/dashboard/form" replace />} />
    </Routes>
  );
};

export default AppRoutes;
