import { createSlice } from "@reduxjs/toolkit";

export const FormDetailsSlice = createSlice({
  name: "FormDetails",
  initialState: {
    data: null,
  },
  reducers: {
    FormDetails: (state: any, action) => {
      // If data already exists, merge the new data with the previous data
      if (state.data) {
        state.data = { ...state.data, ...action.payload };
      } else {
        state.data = action.payload;
      }
    },
  },
});

export const { FormDetails } = FormDetailsSlice.actions;

export default FormDetailsSlice.reducer;
