import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  CardMedia,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DishStatus,
  ChangeDishStatus,
  DeleteDishByDishId,
} from "../../../store/services/DishService";
import EditIcon from "@mui/icons-material/Edit";
import AddDish from "../../store/dish/add-dish";
import { AddDishView } from "../../store/add-dish-view";
import { EditDishView } from "./edit-dish-view";
import { DeleteAdminStoreDish } from "../../../store/reducers/store-reducer";
import { useAppDispatch, useAppSelector } from "../../../store";
import { toast } from "react-toastify";
import getSymbolFromCurrency from "currency-symbol-map";
export interface DishData {
  dishName: string;
  cuisine: string;
  description: string;
  price: number;
  currency: string;
  dishStatus: "AVAILABLE" | "UNAVAILABLE";
  images?: string[];
  isVeg: boolean;
  other?: { [key: string]: string };
  extras?: { [key: string]: any };
  dishId: string;
  restaurantId: string;
}
interface DishCardProps {
  dishData: DishData;
  isPreview: boolean;
}

const EditableDishCard: React.FC<DishCardProps> = ({ dishData }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const [dishStatus, setDishStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleChange = async () => {
    const payload: DishStatus = {
      status: !dishStatus ? "AVAILABLE" : "UNAVAILABLE",
      dishId: dishData.dishId,
    };
    const res = await ChangeDishStatus(payload, dishData.restaurantId);
    if (res?.status === 200) {
      setDishStatus(!dishStatus);
    }
  };

  const handleEdit = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = async () => {
    const res = await DeleteDishByDishId(dishData.restaurantId, dishData);
    if (res.status === 200) {
      toast.success("Dish deleted Successfully", {
        toastId: "dishDeleted",
      });
      dispatch(DeleteAdminStoreDish(dishData));
      setDialogOpen(false);
    }
  };
  useEffect(() => {
    setImageUrls([]);
    if (dishData?.dishStatus) {
      setDishStatus(dishData.dishStatus === "AVAILABLE" ? true : false);
    }
    if (Object.keys(dishData).length) {
      if (dishData?.images) {
        setImageUrls(dishData?.images as any);
      } else {
        setImageUrls([]);
      }
    }
  }, [dishData]);

  return (
    <>
      <Card elevation={5} style={{ width: "350px", margin: "20px auto" }}>
        <CardMedia
          component="img"
          alt={dishData.dishName}
          height="140"
          //https://i.pinimg.com/originals/ee/1d/08/ee1d081c5bdf966b058c1a6588e73e8a.gif
          //https://cdn.dribbble.com/users/645440/screenshots/3266490/loader-2_food.gif
          image={
            imageUrls && imageUrls.length
              ? imageUrls[0]
              : "https://i.pinimg.com/originals/ee/1d/08/ee1d081c5bdf966b058c1a6588e73e8a.gif"
          }
        />

        <div
          style={{
            position: "relative",
            top: "-135px",
            left: "250px",
            width: "44px",
            height: "44px",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            cursor: "pointer",
          }}
          onClick={handleEdit}
        >
          <EditIcon color="warning" />
        </div>
        <div
          style={{
            position: "relative",
            top: "-178px",
            left: "300px",
            width: "44px",
            height: "44px",
            borderRadius: "50%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            background: "white",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            cursor: "pointer",
          }}
        >
          <DeleteIcon color="error" onClick={() => setDialogOpen(true)} />
        </div>
        <CardContent style={{ marginTop: "-100px" }}>
          <Typography variant="h4" component="div">
            {dishData.dishName}
          </Typography>
          <Typography variant="subtitle1" color="textSecondary" gutterBottom>
            {dishData.cuisine}
          </Typography>
          <Typography variant="body2" paragraph>
            {dishData.description}
          </Typography>
          <Typography variant="body1">
            Price: {getSymbolFromCurrency(dishData.currency)} {dishData.price}
          </Typography>
          <Typography variant="body1" style={{ marginTop: "5px" }}>
            Status: <Chip label={dishData.dishStatus} color="primary" />
          </Typography>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h5" align="center">
            Update Dish
          </Typography>
        </DialogTitle>
        <DialogContent>
          <EditDishView dishData={dishData} />
        </DialogContent>
        <DialogActions>
          <Button id="closebtn2" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure want to delete dish ??
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditableDishCard;
