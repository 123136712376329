// FileUpload.tsx
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress, Typography } from "@mui/material";
import {
  getDownloadURL,
  getMetadata,
  ref,
  uploadBytes,
} from "firebase/storage";
import React, { ChangeEvent, useEffect, useState } from "react";
import { v4 } from "uuid";
import { storage } from "../../../keys/firebase";

interface FileUploadProps {
  copyText?: string;
  onFileUpload: (fileUrls: any[]) => void;
  onRemoveFile: (removedFile: any[]) => void;
  isMultiple: boolean;
  accept?: string; // Accept prop for specifying allowed file types (e.g., ".jpg, .png")
  size: string;
  name: string;
  uploadedfiles: any[];
}

const FileUpload: React.FC<FileUploadProps> = ({
  copyText,
  onFileUpload,
  onRemoveFile,
  isMultiple,
  accept,
  size,
  name,
  uploadedfiles,
}) => {
  const [files, setFiles] = useState<File[]>(uploadedfiles);
  const [selectedFileType, setSelectedFileType] = useState("IMAGE");
  const [showLoader, setShowLoader] = useState(false);
  interface File {
    file: string;
    url: string;
    fileType: string;
  }
  const imageRegex = /\.(png|jpg|jpeg|tiff|icon)$/i;
  const pdfRegex = /\.(pdf)$/i;
  const excelRegex = /\.(xlsx|xls|csv)$/i;
  const videoRegex = /\.(mp4|mov|avi|mkv|flv)$/i;
  const audioRegex = /\.(mp3|wav|ogg|flac)$/i;
  const docRegex = /\.(doc|docx|DOC|DOCX)$/i;
  const textRegex = /\.(txt|TEXT)$/i;

  const validateFile = (file: any) => {
    let isValid = true;
    const fileType = file.name.toUpperCase();
    console.log(fileType, accept);
    // Assuming these regex patterns are defined in your globalService
    if (imageRegex.test(fileType)) {
      setSelectedFileType("IMAGE");
    } else if (pdfRegex.test(fileType)) {
      setSelectedFileType("PDF");
    } else if (excelRegex.test(fileType)) {
      setSelectedFileType("EXCEL");
    } else if (videoRegex.test(fileType)) {
      setSelectedFileType("VIDEO");
    } else if (audioRegex.test(fileType)) {
      setSelectedFileType("AUDIO");
    } else if (docRegex.test(fileType)) {
      setSelectedFileType("DOC");
    } else if (textRegex.test(fileType)) {
      setSelectedFileType("TEXT");
    }
    let acceptList = accept?.toLocaleUpperCase().split(",");
    console.log(acceptList, selectedFileType);
    isValid = !!acceptList?.includes(selectedFileType);

    console.log(isValid);

    if (!isValid) {
      alert("Invalid File Type");
      return false;
    }

    // Assuming size is defined in your component state
    const maxSizeInBytes = Number(size) * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert(`File size is too large. Max File size is ${size} MB`);
      return false;
    }

    return isValid;
  };

  const UploadFromClientSide = async (file: any) => {
    const imageReference = ref(storage, `images/upload${v4()}`);
    try {
      await uploadBytes(imageReference, file);
      const imageUrl = await getDownloadURL(imageReference);
      const meta = await getMetadata(imageReference);
      console.log("Image URL:", imageUrl, meta.name, meta.contentType);
      return {
        file: meta.name,
        fileType: String(meta.contentType),
        url: imageUrl,
      };
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleFileUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files?.[0];

    if (!newFile) {
      return;
    }

    // Validate file type
    if (!validateFile(newFile)) {
      // alert('Invalid file type. Please upload a valid file.');
      return;
    }
    setShowLoader(true);
    const formData = new FormData();
    formData.append("filename", newFile);

    if (isMultiple) {
      try {
        //const res = await AxiosApi.post("/uploadFile", formData);
        //const { name, type, downloadURL } = res.data;

        const updatedFiles = [...files];
        const data = await UploadFromClientSide(newFile);

        if (data) {
          const file = {
            file: data.file,
            fileType: data.fileType,
            url: data.url,
          };
          updatedFiles.push(file);
          setFiles(updatedFiles);
          onFileUpload(updatedFiles);
        }

        console.log(updatedFiles);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      try {
        //const res = await AxiosApi.post("/uploadFile", formData);
        //const { name, type, downloadURL } = res.data;
        const data = await UploadFromClientSide(newFile);
        if (data) {
          setFiles([
            {
              file: data.file,
              fileType: data.fileType,
              url: data.url,
            },
          ]);
        }
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }

    setShowLoader(false);
  };
  const handleRemoveFile = (index: number) => {
    const updatedFiles = [...files];

    updatedFiles.splice(index, 1);

    setFiles(updatedFiles);

    onRemoveFile(updatedFiles);
  };

  const handleUpload = () => {
    document.getElementById(name)?.click();
  };

  useEffect(() => {
    setFiles(uploadedfiles);
  }, [uploadedfiles]);

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        id={name}
        onChange={handleFileUpload}
        accept={accept}
      />
      <div
        style={{
          border: "1px solid #263238",
          borderRadius: "8px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={handleUpload}
      >
        {!showLoader && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="40"
            height="40"
          >
            <path d="M12 12.5858L16.2426 16.8284L14.8284 18.2426L13 16.415V22H11V16.413L9.17157 18.2426L7.75736 16.8284L12 12.5858ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z"></path>
          </svg>
        )}
        {showLoader && <CircularProgress />}
        <div style={{ fontWeight: "bold" }}> Upload Files Here </div>
      </div>

      {files.map((file, index) => (
        <div
          key={index}
          style={{
            border: "1px solid #263238",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "2px",
            alignItems: "center",
            padding: "10px",
            marginTop: "15px",
          }}
        >
          <img
            src={file.url}
            alt={`Uploaded File ${index}`}
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
          <Typography variant="overline">{file?.file}</Typography>
          <CloseIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleRemoveFile(index)}
          >
            Remove
          </CloseIcon>
        </div>
      ))}
    </div>
  );
};

export default FileUpload;
