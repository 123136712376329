import AssignmentIcon from "@mui/icons-material/Assignment";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import StoreIcon from "@mui/icons-material/Store";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme, useTheme } from "@mui/material/styles";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BusinessIcon from "@mui/icons-material/Business";
import StorefrontIcon from "@mui/icons-material/Storefront";
import { GetAllDetailsByResId } from "../store/services/FormService";
import { MasterDetails } from "../store/reducers/master-reducer";
import { useAppDispatch, useAppSelector } from "../store";
import LockIcon from "@mui/icons-material/Lock";
import { toast } from "react-toastify";
import { Box, Button, Typography } from "@mui/material";
import { THARASA_LOGO } from "../constants/assets";
import QuizIcon from '@mui/icons-material/Quiz';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';

const drawerWidth = 240;

export const enum APPLICATION_STATUS {
  SUBMITTED = "SUBMMITED",
  ACCEPTED = "ACCEPTED",
}

interface menu {
  url: string;
  icon: JSX.Element;
  name: string;
  islocked: boolean;
}

const sidebarUrls: {
  [key: string]: {
    url: string;
    icon: JSX.Element;
    name: string;
    islocked: boolean;
  };
} = {
  Verification: {
    url: "form",
    name: "Application",
    icon: <AssignmentIcon />,
    islocked: false,
  },
  Store: {
    url: "store",
    name: "Store",
    icon: <StoreIcon />,
    islocked: false,
  },
  StoreManagement: {
    url: "store-management",
    name: "Store Mangement",
    icon: <BusinessIcon />,
    islocked: false,
  },
  Orders: {
    url: "orders",
    name: "Orders",
    icon: <StorefrontIcon />,
    islocked: false,
  },
  paymentDetails: {
    url: "account-view",
    name: "Account",
    icon: <ManageAccountsIcon />,
    islocked: false,
  },
  // addressMap: {
  //   url: "store-address",
  //   name: "Restaurant In Map",
  //   icon: <AddLocationAltIcon />,
  //   islocked: false,
  // },
    faqs: {
    url: "faqs",
    name: "faqs",
    icon: <QuizIcon />,
    islocked: false,
  },
  appDownload: {
    url: "app-download",
    name: "App Download",
    icon: <DownloadForOfflineIcon />,
    islocked: false,
  },
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface SideBarProps {
  open: boolean;
  handleDrawerClose: () => void;
}

const SideBar: FC<SideBarProps> = ({ open, handleDrawerClose }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const restaurantId = useAppSelector(
    (state) => state.userReducer.restaurantId
  );

  const fetchData = async () => {
    try {
      const details = await GetAllDetailsByResId(restaurantId);

      if (details?.status === 200) {
        const { data } = details.data;
        if (data.applicationStatus !== APPLICATION_STATUS.ACCEPTED) {
          lockMenu();
        }
        dispatch(MasterDetails(data.data));
      } else {
        console.error("Error fetching details:", details?.status);
      }
    } catch (error) {
      console.error("Error fetching details:", error);
    }
  };

  const lockMenu = () => {
    const urls = Object.values(sidebarUrls).filter(
      (url) => url.name !== "Application"
    );
    urls.map((url) => {
      url.islocked = true;
      url.icon = <LockIcon />;
    });
    console.log(sidebarUrls);
  };

  const isLockedMenu = (menu: menu) => {
    if (!menu.islocked) return false;
    if (menu.name === "Application") {
      return false;
    } else {
      return true;
    }
  };
  const handleInfo = (menu: menu) => {
    toast.info("Please contact admin to unlock " + menu.name, {
      toastId: "info",
    });
  };
  useEffect(() => {
    if (!restaurantId) return;
    fetchData();
  }, [restaurantId]);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={() => handleDrawerClose()}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <Box
              justifyContent={"center"}
              alignItems={"center"}
              display={"flex"}
              gap={1}
            >
              <Typography variant="caption">THARASA FOOD ADDA</Typography>
              <Box
                component="img"
                sx={{
                  height: 45,
                  width: 45,
                }}
                alt="The house from the offer."
                src={THARASA_LOGO}
              />
              <ChevronLeftIcon />
            </Box>
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {Object.keys(sidebarUrls).map((k, i) => (
          <ListItem key={k} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 56,
                mt: "5px",
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                backgroundColor: `${
                  "/dashboard/" + sidebarUrls[k].url === location.pathname
                    ? theme.palette.primary.main
                    : theme.palette.primary.contrastText
                }`,
                color: `${
                  "/dashboard/" + sidebarUrls[k].url === location.pathname
                    ? theme.palette.primary.contrastText
                    : theme.palette.primary.main
                }`,
                borderRadius: 2,
              }}
              onClick={() =>
                isLockedMenu(sidebarUrls[k])
                  ? handleInfo(sidebarUrls[k])
                  : navigate(sidebarUrls[k].url)
              }
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                  color: `${
                    "/dashboard/" + sidebarUrls[k].url === location.pathname
                      ? theme.palette.primary.contrastText
                      : theme.palette.primary.main
                  }`,
                }}
              >
                {sidebarUrls[k].icon}
              </ListItemIcon>
              <ListItemText
                primary={sidebarUrls[k].name}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box flex={1} display={"flex"} justifyContent={"center"} alignItems={"flex-end"}>
        <Button>v1.0.0</Button>
      </Box>
    </Drawer>
  );
};

export default SideBar;
